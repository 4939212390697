.social-link {
  margin-right: 2rem;
  list-style: none;
}

.social-links:last-child {
  margin-right: 0;
}

.social-links {
  display: flex;
  justify-content: space-between;
}
